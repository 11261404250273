export default {
  facebook: 'https://www.facebook.com/mdsave',
  instagram: 'https://www.instagram.com/mdsave',
  twitter: 'https://twitter.com/mdsave',
  linkedin: 'https://www.linkedin.com/company/mdsave',
  authorize: 'http://www.authorize.net/',
  bbbPlus:
    'http://www.bbb.org/nashville/business-reviews/consultants-medical/mdsave-in-brentwood-tn-37077520#bbbseal',
  aptible: 'http://www.aptible.com',
  klas: '/media/mdsave-rated-klas-research',
};
