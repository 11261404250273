import { makeRequestAction, makeAction } from './index';

export const requestGetItems = makeRequestAction('GET_ITEMS', {
  onSuccess(params, response) {
    return {
      getItems: response,
    };
  },
});

export const removeItem = makeRequestAction('REMOVE_ITEM', {
  onSuccess(params, response) {
    return {
      getItems: response,
    };
  },
});

export const requestChangeQty = makeRequestAction('CHANGE_QTY', {
  onSuccess(params, response) {
    return {
      getItems: { ...response, putItem: false },
    };
  },
});

export const requestClearCart = makeRequestAction('CLEAR_CART', {
  onSuccess(params) {
    params.cb && params.cb();
    return {
      getItems: [],
    };
  },
});

export const requestSetDiscountItem = makeAction('SET_DISCOUNT_ITEM');

export const requestSaveGroupUserData = makeRequestAction('SET_CONTACT_INFO', {
  onSuccess(params, response) {
    params.cb && params.cb();
    if (params.getUserData) {
      return response;
    }

    return {
      hospitalContactPerson: params.hospital_contact_person,
      hospitalPhoneNumber: params.hospital_phone_number,
    };
  },
});

export const requestSetNote = makeRequestAction('SET_NOTE', {
  onSuccess(params, response) {
    params.cb && params.cb();
    return {
      getItems: response,
    };
  },
});

export const requestPutItem = makeRequestAction('PUT_ITEM', {
  onSuccess(params, response) {
    if (response && response?.status === 1) {
      params.cb && params.cb();
    }
    const options = (params && params.params) || undefined;
    return {
      getItems: {
        ...response,
        putItem: true,
        currentMppxId: options && options.mppxId,
      },
      params: options,
    };
  },
});

export const requestSetOpenCart = makeAction('SET_OPEN_CART');

export const requestRemoveStatus = makeAction('REMOVE_STATUS');

export const requestClearAddedItemProcedures = makeAction('CLEAR_FREQUENTLY');

export const requestClearItems = makeAction('CLEAR_CART_ITEMS');
