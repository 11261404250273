export const APP_PORT = window.env.REACT_APP_BASE_PORT;

export const APP_HOST = window.env.REACT_APP_BASE_HOST;

export const APP_APTIBLE_URL = window.env.REACT_APP_APTIBLE_URL;

export const APP_S3_BASE_PATH = window.env.REACT_APP_S3_BASE_PATH;

export const APP_SFBTNID_KNOWN = window.env.REACT_APP_SFBTNID_KNOWN;

export const APP_SFBTNDEPLOYID_KNOWN = window.env.REACT_APP_SFBTNDEPLOYID_KNOWN;

export const APP_SFDEPLOYID = window.env.REACT_APP_SFDEPLOYID;

export const APP_SFBTNPATH = window.env.REACT_APP_SFBTNPATH;

export const APP_SFBTNID_UNKNOWN = window.env.REACT_APP_SFBTNID_UNKNOWN;

export const APP_SFBTNDEPLOYID_UNKNOWN =
  window.env.REACT_APP_SFBTNDEPLOYID_UNKNOWN;

export const APP_SFJSPATH = window.env.REACT_APP_SFJSPATH;

export const APP_CAPTCHA_SITE_KEY = window.env.REACT_APP_CAPTCHA_SITE_KEY;

export const APP_RECAPTCHA_SITE_KEY = window.env.REACT_APP_RECAPTCHA_SITE_KEY;

export const APP_GOOGLE_API_KEY = window.env.REACT_APP_GOOGLE_API_KEY;

export const APP_SEGMENT_TOKEN = window.env.REACT_APP_SEGMENT_TOKEN;

export const APTIBLE_APP = window.env.REACT_APP_APTIBLE_APP;

export const APP_DEPLOY_ENVIRONMENT = window.env.REACT_APP_DEPLOY_ENVIRONMENT;

export const ANALYTICS_MEASUREMENT_ID =
  window.env.REACT_APP_ANALYTICS_MEASUREMENT_ID;

/* env-vars Contentful Graphql */
export const APP_CONTENTFUL_SPACE_ID = window.env.REACT_APP_CONTENTFUL_SPACE_ID;

export const APP_CONTENTFUL_ENVIRONMENT_NAME =
  window.env.REACT_APP_CONTENTFUL_ENVIRONMENT_NAME;

export const APP_CONTENTFUL_CDA_TOKEN =
  window.env.REACT_APP_CONTENTFUL_CDA_TOKEN;

export const APP_CONTENTFUL_CPA_TOKEN =
  window.env.REACT_APP_CONTENTFUL_CPA_TOKEN;

/* env-vars of Apollo Server */
export const APP_APOLLO_SERVER = window.env.REACT_APP_APOLLO_SERVER;

/* Launch Darkly */
export const APP_LAUNCH_DARKLY_CLIENT_SIDE_ID = window.env.REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID;
