const locationKey = 'location';
const providersKey = 'provider';
const insuranceProviderKey = 'insuranceProvider';
const BACK_TO_RESULT_KEY = 'BACK_TO_RESULT';
const IS_EXTERNAL = 'isExternal';
const CAMPAIGN_SELECTED_STATE_KEY = 'selectedLocation';

const sessionWatchDelay = 120000;
const userMinutesToPopup = 15;
const userMinutesToLogout = 20;
const patientMinutesToPopup = 55;
const patientMinutesToLogout = 60;
const csrfRefreshRate = 120000;

export default {
  locationKey,
  providersKey,
  insuranceProviderKey,
  BACK_TO_RESULT_KEY,
  IS_EXTERNAL,
  CAMPAIGN_SELECTED_STATE_KEY,
  sessionWatchDelay,
  userMinutesToPopup,
  userMinutesToLogout,
  patientMinutesToPopup,
  patientMinutesToLogout,
  csrfRefreshRate,
};
