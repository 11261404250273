import { addFlags } from 'actions/launchDarkly';
import entitiesMerge from './entitiesMerge';

const launchDarkly = () => {
  const initialState = {
    flags: {},
  };

  return (state = initialState, action) => {
    switch (action.type) {
      case addFlags.actionName: {
        return {
          ...state,
          flags: action.params.flags,
        };
      }
      default:
        return entitiesMerge(state, action, 'launchDarkly');
    }
  };
};

export default launchDarkly();
