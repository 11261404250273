import Theme from 'md-styled-components/Themes';

export default `
&&&{
  background-color: ${Theme.gold};
  color: ${Theme.white};
  &:hover {
    background-color: ${Theme['secondary-hover-color']};
    color: ${Theme.white};
  }
  
  &:focus {
    background-color: ${Theme.gold};
    color: ${Theme.white};
    box-shadow: ${Theme['btn-shadow-inset']} ${Theme['btn-outer-border-size']} ${Theme.gold},
      ${Theme['btn-shadow-inset']} ${Theme['btn-inner-border-size']} ${Theme.white};
  }
  
  &:active {
    background-color: ${Theme['secondary-active-color']};
    color: ${Theme.white};
  }
  
  &:disabled, &:disabled:hover {
    background-color: ${Theme.I10};
    color: ${Theme.I50} !important;
    path {
      fill: ${Theme.I50} !important;
    }
  }
}
`;
