export default Object.freeze({
  STATUS_DEFAULT: 1,
  TYPE_PROCEDURE: 1,
  STATUS_ERROR: 2,
  STATUS_DISCOUNT: 3,
  SAME_SESSION_DISCOUNT:
    'Additional surgical procedures performed at the same time as the primary procedure by the ' +
    'same medical provider may qualify for a same session discount. ' +
    '\n\n Vouchers for same session procedures must be redeemed together and cannot be cancelled separately.',
});
