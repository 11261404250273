import { combineReducers } from 'redux';
import suggestedProcedures from './suggestedProcedures';
import locations from './locations';
import suggestedProviders from './suggestedProviders';
import suggestedProviderHospitals from './suggestedProviderHospitals';
import analyticData from './analyticData';
import patient from './registeredPatient';
import provider from './registeredProvider';
import loading from './loading';
import error from './error';
import liveAgent from './liveAgent';
import suggestedHospitals from './suggestedHospitals';
import shoppingCart from './shoppingCart';
import geographicLocation from './geographicLocation';
import groups from './groups';
import users from './users';
import campaign from './campaign';
import mppx from './mppx';
import passwordRecovery from './passwordRecovery';
import privateHospitalData from './privateHospitalData';
import patients from './patients';
import messages from './messages';
import procedures from './procedures';
import topProcedures from './topProcedures';
import support from './support';
import transparency from './transparency';
import networkInfo from './networkInfo';
import carePackage from './carePackage';
import emailPreferences from './emailPreferences';
import postPurchase from './postPurchase';
import loginModal from './loginModal';
import alertData from './alertData';
import electronicLink from './electronicLink';
import regionalProcedure from './regionalProcedure';
import launchDarkly from './launchDarkly';

const appReducer = combineReducers({
  suggestedProcedures,
  locations,
  suggestedProviders,
  suggestedProviderHospitals,
  analyticData,
  patient,
  provider,
  loading,
  error,
  liveAgent,
  suggestedHospitals,
  shoppingCart,
  geographicLocation,
  groups,
  users,
  campaign,
  mppx,
  passwordRecovery,
  privateHospitalData,
  patients,
  messages,
  procedures,
  topProcedures,
  support,
  transparency,
  networkInfo,
  carePackage,
  emailPreferences,
  postPurchase,
  loginModal,
  alertData,
  electronicLink,
  regionalProcedure,
  launchDarkly,
});

export default (state, action) => appReducer(state, action);
